class TaskQueue {
    constructor(concurencyLimit = 1) {
        this.concurencyLimit = concurencyLimit;
        this.running = 0;
        this.queue = [];
    }
    push(task) {
        this.queue.push(task);
        this.next();
    }
    next() {
        const done = () => {
            this.running -= 1;
            this.next();
        };
        while (this.running < this.concurencyLimit && this.queue.length) {
            const task = this.queue.shift();
            if (task) {
                task(done);
                this.running += 1;
            }
        }
    }
}
export default TaskQueue;

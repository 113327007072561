import _ from "underscore";
class ComponentUpdateMutable {
    constructor(component) {
        this.extend(component);
    }
    extend(component) {
        Object.assign(this, component);
    }
    findVariable(name) {
        return _.findWhere(this.variables || [], {
            name,
        });
    }
    findOrCreateVariable(name) {
        var _a;
        const variable = (_a = _.findWhere(this.variables || [], {
            name,
        })) !== null && _a !== void 0 ? _a : {
            name,
            type: "STRING",
            values: {
                default: "",
            },
        };
        return variable;
    }
}
export default ComponentUpdateMutable;

import _ from "underscore";
class ComponentCreateMutable {
    constructor(component) {
        this.extend(component);
    }
    extend(component) {
        Object.assign(this, component);
    }
    setAnchors(anchors) {
        this.anchors = anchors;
    }
    findVariable(name) {
        return _.findWhere(this.variables || [], {
            name,
        });
    }
    findOrCreateVariable(name) {
        let variable = _.findWhere(this.variables || [], {
            name,
        });
        if (!variable) {
            variable = {
                name,
                type: "STRING",
                values: {
                    default: "",
                },
            };
            if (!this.variables) {
                this.variables = [];
            }
            this.variables.push(variable);
        }
        return variable;
    }
}
export default ComponentCreateMutable;

import $ from "jquery";
import _ from "underscore";
import Uri from "jsuri";
import { toPromise } from "../promise";
const baseUrl = "api:///v2/campaign-summaries";
export var CampaignState;
(function (CampaignState) {
    CampaignState["NOT_LAUNCHED"] = "NOT_LAUNCHED";
    CampaignState["LIVE"] = "LIVE";
    CampaignState["PAUSED"] = "PAUSED";
    CampaignState["ENDED"] = "ENDED";
    CampaignState["STOPPED"] = "STOPPED";
    CampaignState["ARCHIVED"] = "ARCHIVED";
})(CampaignState || (CampaignState = {}));
const getCampaignSummaries = (queryParams) => {
    const uri = new Uri(baseUrl);
    if (queryParams) {
        _.each(queryParams, (value, key) => {
            uri.addQueryParam(key, value);
        });
    }
    return toPromise($.ajax({
        method: "GET",
        url: uri.toString(),
    }));
};
const getCampaignSummary = (campaignId) => {
    return toPromise($.ajax({
        method: "GET",
        url: `${baseUrl}/${campaignId}`,
    }));
};
export default {
    getCampaignSummaries,
    getCampaignSummary,
};

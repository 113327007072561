import { useMemoize } from "@vueuse/core";
import { debounce } from "lodash";
import { defineStore } from "pinia";
import { breadthFirstSearch, getEdgeId, transposeGraph, } from "src/shared/graph";
import { reactive } from "vue";
import { incrementGlobalComponentGraphVersion } from "./global-component-graph-version";
export const useComponentGraphStore = defineStore("componentGraphStore", () => {
    const parentGraph = reactive({
        nodes: {},
        edges: {},
    });
    const getChildGraph = useMemoize(() => {
        return transposeGraph(parentGraph);
    });
    const getChildComponentIds = useMemoize((componentId, degree = null) => {
        const childGraph = getChildGraph();
        return breadthFirstSearch(childGraph, componentId, degree);
    });
    const getParentComponentIds = useMemoize((componentId) => {
        return breadthFirstSearch(parentGraph, componentId);
    });
    const getRootComponentIds = useMemoize(() => {
        const rootComponents = Object.values(parentGraph.nodes)
            .filter((node) => {
            return getParentComponentIds(node.id).length === 0;
        })
            .map((node) => node.id);
        return rootComponents;
    });
    const getChildTreeRecurse = (componentId, visited = {}) => {
        visited[componentId] = true;
        const root = {
            componentId,
            children: [],
        };
        const childComponentIds = getChildComponentIds(componentId, 1);
        childComponentIds.forEach((childComponentId) => {
            if (visited[childComponentId]) {
                return;
            }
            const childTree = getChildTreeRecurse(childComponentId, visited);
            root.children.push(childTree);
        });
        return root;
    };
    const getChildTree = useMemoize((componentId) => {
        return getChildTreeRecurse(componentId, {});
    });
    const flushCache = debounce(() => {
        getChildGraph.cache.clear();
        getChildComponentIds.cache.clear();
        getParentComponentIds.cache.clear();
        getRootComponentIds.cache.clear();
        getChildTree.cache.clear();
        incrementGlobalComponentGraphVersion();
    }, 100);
    const addComponent = (component) => {
        parentGraph.nodes[component.id] = {
            id: component.id,
        };
        component.component_ids.forEach((id) => {
            const edgeId = getEdgeId(component.id, id);
            parentGraph.edges[edgeId] = {
                source: component.id,
                target: id,
                context: undefined,
            };
        });
        flushCache();
    };
    return {
        parentGraph,
        addComponent,
        getChildComponentIds,
        getParentComponentIds,
        getRootComponentIds,
        getChildTree,
    };
});

import Strings from "src/shared/Strings";
import { parseComponentTypes } from "src/shared/tags/components";
const componentTypesWithCreative = ["EMAIL", "ONSITE"];
export class ComponentCommonImpl {
    constructor(parameters) {
        this.types = [];
        this.name = parameters.name;
        this.types = parseComponentTypes(parameters.tags);
    }
    getDisplayName() {
        return Strings.capitalize(this.name);
    }
    isRoot() {
        return this.name === "root";
    }
    hasCreative() {
        return componentTypesWithCreative.some((type) => this.types.includes(type));
    }
}

import $ from "jquery";
const basePath = (campaignId, componentId) => `api:///v2/campaigns/${campaignId}/components/${componentId}/variables`;
const update = (campaignId, componentId, request) => {
    return $.ajax({
        type: "PUT",
        url: `${basePath(campaignId, componentId)}`,
        contentType: "application/json",
        data: JSON.stringify(request),
    });
};
export default {
    update,
};

import { chain, without } from "lodash";
export const getEdgeId = (source, target) => {
    return `${source}-${target}`;
};
export const transposeGraph = (graph) => {
    const transposedGraph = {
        nodes: {},
        edges: {},
    };
    Object.values(graph.nodes).forEach((node) => {
        transposedGraph.nodes[node.id] = node;
    });
    Object.keys(graph.edges).forEach((edgeId) => {
        const edge = graph.edges[edgeId];
        transposedGraph.edges[edgeId] = {
            source: edge.target,
            target: edge.source,
            context: edge.context,
        };
    });
    return transposedGraph;
};
export const breadthFirstSearch = (graph, start, degree = null, selectEdge = (edges) => edges[0]) => {
    const visited = {};
    const visitedInOrder = [];
    const queue = [];
    queue.push({ node: start, depth: 0 }); // Initialize with depth 0
    while (queue.length > 0) {
        const { node, depth } = queue.shift();
        if (!visited[node]) {
            visited[node] = true;
            visitedInOrder.push(node);
            if (degree !== null && depth === degree) {
                continue; // If degree is reached, skip adding new targets
            }
            const edgesGroupedByTarget = chain(Object.values(graph.edges))
                .filter((edge) => edge.source === node)
                .groupBy((edge) => edge.target)
                .value();
            const targets = chain(Object.values(edgesGroupedByTarget))
                .map((edges) => {
                return selectEdge(edges);
            })
                .map((edge) => edge === null || edge === void 0 ? void 0 : edge.target)
                .compact()
                .value();
            targets.forEach((target) => {
                if (!visited[target]) {
                    queue.push({ node: target, depth: depth + 1 });
                }
            });
        }
    }
    return without(visitedInOrder, start);
};

import _ from "underscore";
import { ComponentCommonImpl } from "../../component/model/ComponentCommonImpl";
import BuiltCampaignComponentVariable from "./BuiltCampaignComponentVariable";
import { parseComponentTypes } from "src/shared/tags/components";
const calculateDefaultPriorityBasedOnResponsePosition = (variableResponse, index) => {
    if (variableResponse.priority === "100") {
        return `100.${index}`;
    }
    return variableResponse.priority;
};
class BuiltComponent {
    constructor(builtComponentResponse) {
        this.id = builtComponentResponse.id;
        this.name = builtComponentResponse.name;
        this.owner = builtComponentResponse.owner;
        this.component_version = builtComponentResponse.component_version;
        this.campaign_id = builtComponentResponse.campaign_id;
        this.description = builtComponentResponse.description;
        this.tags = builtComponentResponse.tags;
        this.types = parseComponentTypes(this.tags);
        this.absolute_names = builtComponentResponse.absolute_names;
        this.component_ids = builtComponentResponse.component_ids;
        this.created_date = builtComponentResponse.created_date;
        this.updated_date = builtComponentResponse.updated_date;
        this.variables = {};
        builtComponentResponse.variables.forEach((variableResponse, index) => {
            variableResponse.priority =
                calculateDefaultPriorityBasedOnResponsePosition(variableResponse, index);
            this.variables[variableResponse.name] =
                new BuiltCampaignComponentVariable(variableResponse, {
                    componentId: this.id,
                    campaignId: this.campaign_id,
                });
        });
        this.componentCommonImpl = new ComponentCommonImpl(builtComponentResponse);
        this.types = this.componentCommonImpl.types;
    }
    findVariable(name) {
        return this.variables[name];
    }
    listVariables() {
        return _.values(this.variables);
    }
    isRoot() {
        return this.componentCommonImpl.isRoot();
    }
    getDisplayName() {
        return this.componentCommonImpl.getDisplayName();
    }
    hasCreative() {
        return this.componentCommonImpl.hasCreative();
    }
}
export default BuiltComponent;

import $ from "jquery";
const basePath = (campaignId, componentId) => `api:///v2/campaigns/${campaignId}/components/${componentId}/variables`;
const create = (campaignId, componentId, request) => {
    return $.ajax({
        type: "POST",
        url: basePath(campaignId, componentId),
        contentType: "application/json",
        data: JSON.stringify(request),
    });
};
const update = (campaignId, componentId, variableName, request) => {
    return $.ajax({
        type: "PUT",
        url: `${basePath(campaignId, componentId)}/${variableName}`,
        contentType: "application/json",
        data: JSON.stringify(request),
    });
};
const destroy = (campaignId, componentId, variableName) => {
    return $.ajax({
        type: "DELETE",
        url: `${basePath(campaignId, componentId)}/${variableName}`,
    });
};
const get = (campaignId, componentId, variableName) => {
    return $.ajax({
        type: "GET",
        url: `${basePath(campaignId, componentId)}/${variableName}`,
    });
};
const list = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: basePath(campaignId, componentId),
    });
};
const getBuilt = (campaignId, componentId, variableName) => {
    return $.ajax({
        type: "GET",
        url: `${basePath(campaignId, componentId)}/${variableName}/built`,
    });
};
const listBuilt = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: `${basePath(campaignId, componentId)}/built`,
    });
};
export default {
    create,
    get,
    list,
    destroy,
    update,
    getBuilt,
    listBuilt,
};

export const isCampaignComponentEnumVariableResponse = (response) => {
    return response.type === "ENUM";
};
export const isCampaignComponentStringVariableResponse = (response) => {
    return response.type === "STRING";
};
export const isCampaignComponentBooleanVariableResponse = (response) => {
    return response.type === "BOOLEAN";
};
export const isCampaignComponentIntegerVariableResponse = (response) => {
    return response.type === "INTEGER";
};
export const isCampaignComponentStringListVariableResponse = (response) => {
    return response.type === "STRING_LIST";
};
export const isCampaignComponentIntegerListVariableResponse = (response) => {
    return response.type === "INTEGER_LIST";
};
export const isCampaignComponentJsonVariableResponse = (response) => {
    return response.type === "JSON";
};
export const isCampaignComponentColorVariableResponse = (response) => {
    return response.type === "COLOR";
};
export const isCampaignComponentImageVariableResponse = (response) => {
    return response.type === "IMAGE";
};
export const isCampaignComponentUrlVariableResponse = (response) => {
    return response.type === "URL";
};
export const isCampaignComponentClientKeyFlowVariableResponse = (response) => {
    return response.type === "CLIENT_KEY_FLOW";
};
export const isCampaignComponentClientKeyVariableResponse = (response) => {
    return response.type === "CLIENT_KEY";
};
export const isCampaignComponentExtoleClientKeyVariableResponse = (response) => {
    return response.type === "EXTOLE_CLIENT_KEY";
};
export const isCampaignComponentRewardSupplierIdVariableResponse = (response) => {
    return response.type === "REWARD_SUPPLIER_ID";
};
// AUDIENCE
export const isCampaignComponentAudienceIdVariableResponse = (response) => {
    return response.type === "AUDIENCE_ID";
};

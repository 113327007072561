import _ from "underscore";
import ComponentCreateMutable from "../../component-deprecated/model/ComponentCreateMutable";
import ComponentUpdateMutable from "../../component-deprecated/model/ComponentUpdateMutable";
import ComponentTypes from "../../component-deprecated/model/ComponentTypes";
import { ComponentCommonImpl } from "./ComponentCommonImpl";
import { createCampaignComponentVariables } from "../../campaign-component/variable/model/factory/campaignComponentVariableFactory";
const SIGNS_REGEX = /[^a-zA-Z0-9]/g;
class Component {
    constructor(componentResponse) {
        var _a;
        this.componentCommonImpl = new ComponentCommonImpl(componentResponse);
        this.id = componentResponse.id;
        this.name = componentResponse.name;
        this.owner = componentResponse.owner;
        this.campaign_id = componentResponse.campaign_id;
        this.description = componentResponse.description;
        this.tags = componentResponse.tags;
        this.type =
            (_a = _.find(ComponentTypes, (value) => { var _a, _b; return (_b = (_a = this.tags) === null || _a === void 0 ? void 0 : _a.includes(value)) !== null && _b !== void 0 ? _b : false; })) !== null && _a !== void 0 ? _a : ComponentTypes.ALL;
        this.variables = createCampaignComponentVariables(componentResponse.variables, {
            campaignId: this.campaign_id,
            componentId: this.id,
        });
        this.types = this.componentCommonImpl.types;
    }
    getDisplayName() {
        return this.componentCommonImpl.getDisplayName();
    }
    isRoot() {
        return this.componentCommonImpl.isRoot();
    }
    toComponentCreateMutable(payload) {
        return new ComponentCreateMutable(_.extend({
            id: this.id,
            name: this.name,
            tags: this.tags,
            description: this.description,
            variables: this.getComponentVariablesMutableList(),
        }, payload));
    }
    toComponentUpdateMutable() {
        return new ComponentUpdateMutable({
            id: this.id,
            name: this.name,
            tags: this.tags,
            campaign_id: this.campaign_id,
            description: this.description,
            variables: this.getComponentVariablesMutableList(),
        });
    }
    belongsTo(owners) {
        return !owners || !owners.length || owners.includes(this.owner);
    }
    matchesQuery(query) {
        var _a, _b;
        if (!query) {
            return true;
        }
        const queryRegex = new RegExp((query || "").replace(SIGNS_REGEX, ".*?"), "i");
        return (queryRegex.test((_a = this.name) !== null && _a !== void 0 ? _a : "") ||
            queryRegex.test((_b = this.description) !== null && _b !== void 0 ? _b : ""));
    }
    findVariable(name) {
        return _.findWhere(this.variables || [], {
            name,
        });
    }
    getValueFromTags(regex) {
        var _a;
        const foundTag = _.find(this.tags || [], (tag) => regex.test(tag));
        return (_a = foundTag === null || foundTag === void 0 ? void 0 : foundTag.replace(regex, "")) !== null && _a !== void 0 ? _a : "";
    }
    getComponentVariablesMutableList() {
        return _.map(this.variables || [], (variable) => {
            const result = {
                name: variable.name,
                type: variable.type,
                values: variable.values,
            };
            return result;
        });
    }
    hasCreative() {
        return this.types.includes("EMAIL") || this.types.includes("ONSITE");
    }
    hasAudienceVariable() {
        return !!this.findVariable("havingAnyAudienceId");
    }
}
export default Component;

import { memoize } from "lodash";
import { reactive } from "vue";
class CampaignComponentIndex {
    constructor() {
        this.index = reactive({});
    }
    getComponentIds() {
        return Object.keys(this.index);
    }
    addComponentId(componentId) {
        this.index[componentId] = true;
    }
    removeComponentId(componentId) {
        delete this.index[componentId];
    }
}
const provideCampaignComponentIndex = memoize((campaignId) => {
    return new CampaignComponentIndex();
});
export const createCampaignComponentIndex = () => {
    return {
        get: provideCampaignComponentIndex,
    };
};

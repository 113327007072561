import Strings from "../../../../../shared/Strings";
import { getComponentVariableCategories, getComponentVariableImportance, isComponentVariableTranslatable, isComponentVariableUIDisplayOnly, } from "../../../../../shared/tags/component-variables";
const advancedImportanceLevels = [
    "EXPERT",
    "ADVANCED",
];
const hardCodedUIDisplayOnlyVariableNames = new Set(["variants"]);
export class CampaignComponentVariableCommonImpl {
    constructor(parameters) {
        this.display_name = null;
        this.tags = [];
        this.name = parameters.name;
        this.display_name = parameters.display_name;
        this.tags = parameters.tags;
        const { primary, secondary } = getComponentVariableCategories(parameters.type, parameters.tags);
        this.primaryCategory = primary;
        this.secondaryCategory = secondary;
        this.values = parameters.values;
    }
    getVariants() {
        return Object.keys(this.values);
    }
    getDisplayName() {
        if (this.display_name !== null) {
            return this.display_name;
        }
        return Strings.capitalizeCamelCase(this.name);
    }
    isUIDisplayOnly() {
        if (hardCodedUIDisplayOnlyVariableNames.has(this.name)) {
            return true;
        }
        return isComponentVariableUIDisplayOnly(this.tags);
    }
    getImportance() {
        return getComponentVariableImportance(this.tags);
    }
    isTranslatable() {
        return isComponentVariableTranslatable(this.tags);
    }
    isHidden() {
        return this.getImportance() === "EXPERT";
    }
    getPrimaryCategory() {
        return this.primaryCategory || "other";
    }
    getSecondaryCategory() {
        return this.secondaryCategory || "other";
    }
    isAdvanced() {
        return advancedImportanceLevels.includes(this.getImportance());
    }
    isMultiVariant() {
        return this.getVariants().length > 1;
    }
    hasVariant(variant) {
        return Object.prototype.hasOwnProperty.call(this.values, variant);
    }
    getValue(variant) {
        return this.values[variant];
    }
}

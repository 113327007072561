var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, toValue } from "vue";
import { useBuiltComponentStore } from "..";
import { AsyncPrimer } from "../../common/async-primer";
import { isEmpty } from "underscore";
const asyncPrimer = new AsyncPrimer((key) => key.componentId, ({ componentId }) => __awaiter(void 0, void 0, void 0, function* () {
    const builtComponentStore = useBuiltComponentStore();
    const existingComponent = builtComponentStore.getBuiltComponent(componentId);
    if (isEmpty(existingComponent)) {
        yield builtComponentStore.fetchBuiltComponent(componentId);
    }
}));
export const useBuiltComponent = (componentId) => {
    const builtComponentStore = useBuiltComponentStore();
    const { isLoading, isReady } = asyncPrimer.prime(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return undefined;
        }
        return { componentId: componentIdValue };
    });
    const builtComponent = computed(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return undefined;
        }
        return builtComponentStore.getBuiltComponent(componentIdValue);
    });
    return {
        isLoading,
        isReady,
        builtComponent,
    };
};

import $ from "jquery";
const PATH = "api:///v1/components";
const list = (filter = {}) => {
    return $.ajax({
        method: "GET",
        url: PATH,
        data: filter,
    });
};
const get = (componentId) => $.ajax({
    method: "GET",
    url: `${PATH}/${componentId}`,
});
const duplicate = (componentId, componentDuplicateRequest) => {
    return $.ajax({
        type: "POST",
        url: `${PATH}/${componentId}/duplicate`,
        contentType: "application/json",
        data: JSON.stringify(componentDuplicateRequest),
    });
};
const deleteComponent = (componentId) => {
    return $.ajax({
        type: "DELETE",
        url: `${PATH}/${componentId}`,
    });
};
const getAnchors = (componentId) => {
    return $.ajax({
        type: "GET",
        url: `${PATH}/${componentId}/anchors`,
    });
};
const listBuilt = (filter = {}) => {
    return $.ajax({
        method: "GET",
        url: `${PATH}/built`,
        data: filter,
    });
};
const getBuilt = (componentId) => $.ajax({
    method: "GET",
    url: `${PATH}/${componentId}/built`,
});
export default {
    get,
    list,
    getBuilt,
    listBuilt,
    duplicate,
    deleteComponent,
    getAnchors,
};

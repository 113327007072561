import $ from "jquery";
const getUrl = (campaignId, componentId) => `api:///v2/campaigns/${campaignId}/components/${componentId}`;
const get = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: getUrl(campaignId, componentId),
    });
};
const create = (campaignId, data) => {
    return $.ajax({
        type: "POST",
        url: `api:///v2/campaigns/${campaignId}/components`,
        contentType: "application/json",
        data: JSON.stringify(data),
    });
};
const update = (campaignId, componentId, campaignComponentUpdateRequest) => {
    return $.ajax({
        type: "PUT",
        url: getUrl(campaignId, componentId),
        contentType: "application/json",
        data: JSON.stringify(campaignComponentUpdateRequest),
    });
};
const deleteCampaignComponent = (campaignId, componentId) => {
    return $.ajax({
        type: "DELETE",
        url: getUrl(campaignId, componentId),
    });
};
const list = (campaignId) => {
    return $.ajax({
        type: "GET",
        url: `api:///v2/campaigns/${campaignId}/components`,
    });
};
const listBuilt = (campaignId) => {
    return $.ajax({
        type: "GET",
        url: `api:///v2/campaigns/${campaignId}/components/built`,
    });
};
const getBuilt = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: `api:///v2/campaigns/${campaignId}/components/${componentId}/built`,
    });
};
export default {
    get,
    create,
    list,
    update,
    deleteCampaignComponent,
    getBuilt,
    listBuilt,
};

import Backbone from "backbone";
import _ from "underscore";
const eventBus = _.extend({}, Backbone.Events);
export var EVENT;
(function (EVENT) {
    EVENT["CAMPAIGN_INVALIDATED"] = "CAMPAIGN_INVALIDATED";
    EVENT["CAMPAIGNS_INVALIDATED"] = "CAMPAIGNS_INVALIDATED";
    EVENT["CAMPAIGN_DRAFT_PUBLISHED"] = "CAMPAIGN_PUBLISHED";
    EVENT["CAMPAIGN_DRAFT_DISCARDED"] = "CAMPAIGN_DRAFT_DISCARDED";
})(EVENT || (EVENT = {}));
export const campaignModuleEventBus = {
    campaignInvalidated(campaignId) {
        this.publish(EVENT.CAMPAIGN_INVALIDATED, {
            campaignId,
        });
    },
    campaignsInvalidated() {
        this.publish(EVENT.CAMPAIGNS_INVALIDATED);
    },
    publish(event, payload) {
        eventBus.trigger(event, payload);
    },
    subscribe(event, callback) {
        eventBus.on(event, callback);
    },
};

import CampaignComponentVariable from "../CampaignComponentVariable";
import { CampaignComponentEnumVariable } from "../CampaignComponentEnumVariable";
import { isCampaignComponentBooleanVariableResponse, isCampaignComponentEnumVariableResponse, isCampaignComponentIntegerVariableResponse, isCampaignComponentStringVariableResponse, isCampaignComponentAudienceIdVariableResponse, isCampaignComponentClientKeyFlowVariableResponse, isCampaignComponentClientKeyVariableResponse, isCampaignComponentColorVariableResponse, isCampaignComponentExtoleClientKeyVariableResponse, isCampaignComponentImageVariableResponse, isCampaignComponentJsonVariableResponse, isCampaignComponentRewardSupplierIdVariableResponse, isCampaignComponentStringListVariableResponse, isCampaignComponentUrlVariableResponse, isCampaignComponentIntegerListVariableResponse, } from "./type-guards";
const subclasses = {
    STRING: CampaignComponentVariable,
    BOOLEAN: CampaignComponentVariable,
    INTEGER: CampaignComponentVariable,
    STRING_LIST: CampaignComponentVariable,
    INTEGER_LIST: CampaignComponentVariable,
    JSON: CampaignComponentVariable,
    COLOR: CampaignComponentVariable,
    IMAGE: CampaignComponentVariable,
    URL: CampaignComponentVariable,
    CLIENT_KEY_FLOW: CampaignComponentVariable,
    CLIENT_KEY: CampaignComponentVariable,
    EXTOLE_CLIENT_KEY: CampaignComponentVariable,
    REWARD_SUPPLIER_ID: CampaignComponentVariable,
    ENUM: CampaignComponentEnumVariable,
    AUDIENCE_ID: CampaignComponentVariable,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createCampaignComponentVariable = (type, response, context) => {
    const VariableClass = subclasses[type];
    return new VariableClass(response, context);
};
export const createCampaignComponentVariables = (responses, context) => {
    const variables = [];
    responses.forEach((response) => {
        switch (response.type) {
            case "STRING":
                if (isCampaignComponentStringVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("STRING", response, context));
                }
                break;
            case "BOOLEAN":
                if (isCampaignComponentBooleanVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("BOOLEAN", response, context));
                }
                break;
            case "INTEGER":
                if (isCampaignComponentIntegerVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("INTEGER", response, context));
                }
                break;
            case "STRING_LIST":
                if (isCampaignComponentStringListVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("STRING_LIST", response, context));
                }
                break;
            case "INTEGER_LIST":
                if (isCampaignComponentIntegerListVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("INTEGER_LIST", response, context));
                }
                break;
            case "JSON":
                if (isCampaignComponentJsonVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("JSON", response, context));
                }
                break;
            case "COLOR":
                if (isCampaignComponentColorVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("COLOR", response, context));
                }
                break;
            case "IMAGE":
                if (isCampaignComponentImageVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("IMAGE", response, context));
                }
                break;
            case "URL":
                if (isCampaignComponentUrlVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("URL", response, context));
                }
                break;
            case "CLIENT_KEY_FLOW":
                if (isCampaignComponentClientKeyFlowVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("CLIENT_KEY_FLOW", response, context));
                }
                break;
            case "CLIENT_KEY":
                if (isCampaignComponentClientKeyVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("CLIENT_KEY", response, context));
                }
                break;
            case "EXTOLE_CLIENT_KEY":
                if (isCampaignComponentExtoleClientKeyVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("EXTOLE_CLIENT_KEY", response, context));
                }
                break;
            case "REWARD_SUPPLIER_ID":
                if (isCampaignComponentRewardSupplierIdVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("REWARD_SUPPLIER_ID", response, context));
                }
                break;
            case "ENUM":
                if (isCampaignComponentEnumVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("ENUM", response, context));
                }
                break;
            case "AUDIENCE_ID":
                if (isCampaignComponentAudienceIdVariableResponse(response)) {
                    variables.push(createCampaignComponentVariable("AUDIENCE_ID", response, context));
                }
                break;
            default:
                assertNever(response.type);
        }
    });
    return variables;
};

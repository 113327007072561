export const parseComponentTypes = (tags) => {
    const output = new Set();
    if (tags.includes("type:cta")) {
        output.add("ONSITE");
    }
    if (tags.includes("type:business-event")) {
        output.add("BUSINESS_EVENT");
    }
    if (tags.includes("type:email")) {
        output.add("EMAIL");
    }
    if (tags.includes("type:integration")) {
        output.add("INTEGRATION");
    }
    if (tags.includes("type:rule")) {
        output.add("RULE");
    }
    if (tags.some((tag) => tag.startsWith("promotion-type:"))) {
        output.add("PROMOTION");
    }
    if (tags.includes("promotion-type:onsite")) {
        output.add("ONSITE");
    }
    if (tags.includes("promotion-type:email")) {
        output.add("EMAIL");
    }
    if (tags.includes("type:journey")) {
        output.add("JOURNEY");
    }
    return Array.from(output);
};
